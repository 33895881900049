import React from 'react';

import cx from 'classnames';

export type PageSectionProps = {
    children?: React.ReactNode;
    inner?: boolean;
    extraClass?: string;
    isFullWidth?: boolean;
    padding?: 'top' | 'bottom' | 'block';
    hasFullWidthOnMobile?: boolean;
    color?: 'orange' | 'yellow' | 'white' | 'peach';
};

function PageSection(props: PageSectionProps) {
    const {
        children,
        inner,
        isFullWidth,
        extraClass,
        hasFullWidthOnMobile,
        color = 'white',
        padding,
    } = props;

    const className = cx({
        page__section: true,
        [`page__section--${color}`]: color,
        [`page__section--padding-${padding}`]: padding,
        'page__section--full-width': isFullWidth,
        'page__section-mobile-full-width': hasFullWidthOnMobile,
        [extraClass as string]: extraClass,
    });

    const content = !inner ? children : <div className="page__section-inner">{children}</div>;

    return <section className={className}>{content}</section>;
}

export default PageSection;
